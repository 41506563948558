<script lang="ts" setup>
const props = withDefaults(
	defineProps<{
		duration?: number;
		distance?: number;
		iterationCount?: number | string;
		mode?: "up-to-down" | "down-to-up" | "left-to-right" | "right-to-left";
		active?: boolean;
	}>(),
	{ duration: 1, distance: 10, iterationCount: 1, mode: "up-to-down", active: false }
);

const durationVar = `${props.duration}s`;
const isVertical = props.mode === "up-to-down" || props.mode === "down-to-up";
const isNegative = props.mode === "up-to-down" || props.mode === "left-to-right";
const distance = isNegative ? -props.distance : props.distance;
const positiveRevert = props.iterationCount === 1 || props.iterationCount === 0 ? 0 : -distance;
const negativeRevert = -positiveRevert;

const transformValues1 = `translate${isVertical ? "Y" : "X"}(${distance}px)`;
const transformValues2 = `translate${isVertical ? "Y" : "X"}(${positiveRevert}px)`;
const transformValues3 = `translate${isVertical ? "Y" : "X"}(${negativeRevert}px)`;
</script>
<template>
	<div :class="{ 'play-animation': props.active }"><slot /></div>
</template>

<style scoped lang="scss">
.play-animation {
	animation-name: slide;
	animation-duration: v-bind(durationVar);
	animation-iteration-count: v-bind(iterationCount);
}

@keyframes slide {
	0% {
		transform: v-bind(transformValues1);
	}
	50% {
		transform: v-bind(transformValues2);
	}
	100% {
		transform: v-bind(transformValues3);
	}
}
</style>
